import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '../views/HomeView.vue';
import useAuth from '@/hooks/useAuth'; 
import categories from '@/hooks/useCats';

// define routes
const routes = [
  {
    path: '/',
    name: 'home',
    meta: { layout: 'main', auth: true },
    component: HomeView,
  },
  {
    path: '/login',
    name: 'login',
    meta: { layout: 'empty' },
    component: () => import('../views/LoginView.vue'),
  },
  {
    path: '/passwd-recovery-link',
    name: 'passwd-recovery-link',
    meta: { layout: 'empty' },
    component: () => import('../views/PasswdLinkNewView.vue'),
  },
  {
    path: '/passwd-recovery',
    name: 'passwd-recovery',
    meta: { layout: 'empty' },
    component: () => import('../views/PasswdLinkUseView.vue'),
  },
  {
    path: '/logout',
    name: 'logout',
    meta: { layout: 'empty' },
    beforeEnter: (to, from, next) => {
      useAuth.logout();
      next('/login');
    }
  },
  {
    path: '/about',
    name: 'about',
    meta: { layout: 'main', auth: true },
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
  },
  {
    path: '/:catchAll(.*)',
    name: 'forbidden',
    meta: { layout: 'main', auth: true },
    component: () => import(/* webpackChunkName: "about" */ '../views/forbidden.vue'),
  },
  {
    path: "/:catchAll(.*)",
    name: "NotFound",
    meta: { layout: 'main', auth: true },
    component: () => import(/* webpackChunkName: "NotFound" */ '@/components/NotFoundComponent.vue'),
  },
];

// create router instance
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// check if user is logged in
const currentUser = useAuth.getCurrentUser();
if (!currentUser) {
  router.push('/login?message=XM014');
}

// refresh access token
useAuth.refreshAccessToken(() => {
  router.push('/login?message=XM014');
});

// register routes from categories
categories.regRoutes(router);

// check if user has permissions
router.beforeEach(async (to, from, next) => {
  const currentUser = useAuth.getCurrentUser();
  const requireAuth = to.matched.some((record) => record.meta.auth);

  if (requireAuth && !currentUser) {
    next('/login?message=XM014');
    return;
  }

  next();
});

export default router;
